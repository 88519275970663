import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

export const DigitalClock: FC = () => {
  const [date, setDate] = useState(new Date())

  let interval: NodeJS.Timeout

  useEffect(() => {
    interval = setInterval(() => tick(), 1000)
    return () => clearInterval(interval)
  }, [])

  const tick = () => {
    setDate(new Date())
  }

  return (
    <Clock>
      <Time>{moment(date).format('hh:mm')}</Time>
      <Second>{moment(date).format('A')}</Second>
    </Clock>
  )
}

const Clock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 30%;
  background-color: #222;
  border: 1px solid #333;
`

const Time = styled.div`
  font-family: 'Lexa';
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  color: white;
`

const Second = styled.div`
  font-family: 'Lexa';
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  color: white;
`
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

interface Props {
  text: string
}

export const ScrambleText: FC<Props> = ({ text }) => {
  const [currentText, setCurrentText] = useState<string>('')

  // useEffect(() => {
  //   const a = new Array(text.length).fill(1).map(() => (Math.random() >= .5) ? 1 : 0).join('').toString()
  //   for (let i = 0; i <= text.length; i++) {
  //     setTimeout(() => {
  //       setCurrentText(a.slice(0, i))
  //     }, i * 100)
  //   }
  //   for (let i = 0; i <= text.length; i++) {
  //     setTimeout(() => {
  //       setCurrentText(text.slice(0, i) + a.slice(i))
  //     }, i * 200)
  //   }
  // }, [text])

  return (
    <Container>
      {text.split(/(\s+)/).map((word, index) =>
        <div key={index} className="single-word">
          {word.split('').map((char, index) =>
            <div key={index} className="single-char">{char}</div>
          )}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  /* display: flex; */
`

import { FC, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

interface Degrees {
  hour: number
  minute: number
  second: number
}

export const AnalogClock: FC = () => {
  const [date, setDate] = useState(new Date())

  let interval: NodeJS.Timeout

  useEffect(() => {
    interval = setInterval(() => tick(), 1000)
    return () => clearInterval(interval)
  }, [])

  const tick = () => {
    setDate(new Date())
  }

  const [degrees, setDegrees] = useState<Degrees>({
    hour: 0,
    minute: 0,
    second: 0
  })

  useEffect(() => {
    setDegrees({
      hour: getHourRotation(),
      minute: getMinuteRotation(),
      second: getSecondRotation()
    })
  }, [])

  const getHourRotation = () => {
    const value = date.getHours()
    const rotation = (value * 360) / 12
    return rotation
  }

  const getMinuteRotation = () => {
    const value = date.getMinutes()
    const rotation = (value * 360) / 60
    return rotation
  }

  const getSecondRotation = () => {
    const value = date.getSeconds()
    const rotation = (value * 360) / 60
    return rotation
  }

  return (
    <Clock>
      <Circle />
      <Hour
        seconds={216000}
        degree={degrees.hour}
      />
      <Minute
        seconds={3600}
        degree={degrees.minute}
      />
      <Center />
      <Second
        seconds={60}
        degree={degrees.second}
      />
      <Dot />
    </Clock>
  )
}

const Clock = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  /* border: 4px solid #eee; */
  border-radius: 30%;
  background-color: white;
  box-sizing: border-box;
`

const rotate = (degree: number) => keyframes`
  from {
    transform: translate(-50%, -100%) rotate(${degree}deg);
  }
  to {
    transform: translate(-50%, -100%) rotate(${degree + 360}deg);
  }
`;

const Stick = styled.div`
  position: absolute;
  background-color: #222;
  /* border: 3px solid white; */
  transform-origin: bottom center;
  transition: transform .5s ease-in-out;
  transform: translate(-50%, -100%) translateX(-1px);
  box-shadow: 1px 1px 2px #666;
`

const Hour = styled(Stick) <{ degree: number, seconds: number }>`
  animation: ${(props) => rotate(props.degree)} ${(props) => props.seconds}s linear infinite;
  width: 4px;
  height: 30%;
  top: 50%;
  left: 50%;
  /* border-radius: 8px 8px 0 0; */
`

const Minute = styled(Stick) <{ degree: number, seconds: number }>`
  animation: ${(props) => rotate(props.degree)} ${(props) => props.seconds}s linear infinite;
  width: 4px;
  height: 45%;
  top: 50%;
  left: 50%;
  /* border-radius: 6px 6px 0 0; */
`

const Second = styled(Stick) <{ degree: number, seconds: number }>`
  animation: ${(props) => rotate(props.degree)} ${(props) => props.seconds}s linear infinite;
  width: 2px;
  height: 45%;
  top: 50%;
  left: 50%;
  /* border-radius: 4px 4px 0 0; */
  background-color: #ff0000;
  border: none;
`

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2%;
  height: 2%;
  background-color: white;
  border: 8px solid #222;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`

const Dot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2%;
  height: 2%;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`
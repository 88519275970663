import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { AnalogClock, DigitalClock } from 'components'
import { Images } from 'assets'

export const App: FC = () => {
  const [width, setWidth] = useState<number>(0)

  const getWidth = () => {
    setWidth(document.body.clientWidth)
  }

  useEffect(() => {
    window.addEventListener('load', getWidth, false)
    window.addEventListener('resize', getWidth, false)
  }, [])

  const main = useRef<HTMLDivElement>(null)

  const imgRef = useRef(null)

  return (
    <Container ref={main}>
      <div >
        <Profile>
          <Name
            $size={width * .1}
            $stroke={width * .009}
            data-speed="0.5"
            className="box box-a"
          >
            ferRy
          </Name>
          <Name
            $size={width * .1}
            $stroke={width * .009}
            data-speed="0.8"
            className="box box-b"
          >
            feriAWan
          </Name>
          <Frontend
            $size={width * .06}
          >
            &lt;FRONT_END DEVELOPER&gt;
          </Frontend>
          <Logo ref={imgRef} />
        </Profile>
        <WidgetClock>
          <AnalogClock />
          <DigitalClock />
        </WidgetClock>
      </div>
    </Container>
  )
}

const Container = styled.div`
  background-color: white;
  height: 100vh;
`

const Profile = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
`

const Logo = styled.img.attrs({ src: Images.logo })`
  display: block;
  height: 8rem;
  margin: 0 auto;
  margin-top: 1rem;
`

const Name = styled.div<{ $size: number, $stroke: number }>`
  font-family: 'Plaztma';
  font-size: ${props => props.$size}px;
  text-align: center;
  -webkit-text-stroke-width: ${props => props.$stroke}px;
  -webkit-text-stroke-color: black;
  color: white;
  &:hover {
    -webkit-text-stroke-width: 0px;
    color: black;
  }
`

const Frontend = styled.div<{ $size: number }>`
  font-family: 'Raumdeuter';
  font-size: ${props => props.$size}px;
  text-align: center;
`

const Sign = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  font-family: 'Nathaly-Couple';
  font-size: 2rem;
  text-align: center;
`

const WidgetClock = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  gap: .5rem;
  /* background-color: #4d4d4d; */
  border-radius: 2.4rem;
  border: 1px solid #ddd;
  padding: .5rem;
  @media only screen and (max-width: 500px) {
    left: 50%;
    transform: translateX(-50%);
  }
`
